<template>

    <div class="body-bg-light d-flex align-items-center justify-content-center h-100 flex-column">
        <div class="sticky-header bg-white p-2 text-center d-flex align-items-center justify-content-center">
            <b-button @click="previousStep" :disabled="tabIndex === 0" class="mobile-back d-block d-md-none">
                <svg viewBox="0 0 15.3612 12.3286" >
                    <g>
                        <rect height="12.3286" opacity="0" width="15.3612" x="0" y="0"/>
                        <path d="M0 6.14546C0 6.41282 0.115749 6.67946 0.309264 6.86765L5.43466 11.9827C5.64059 12.1858 5.8767 12.2884 6.12134 12.2884C6.68302 12.2884 7.08212 11.8929 7.08212 11.3607C7.08212 11.0763 6.96212 10.8366 6.78495 10.6673L5.03495 8.90341L2.26416 6.36879L1.89886 6.9578L4.7449 7.11902L13.9594 7.11902C14.5545 7.11902 14.955 6.72313 14.955 6.14546C14.955 5.5678 14.5545 5.17191 13.9594 5.17191L4.7449 5.17191L1.89886 5.33313L2.26416 5.92995L5.03495 3.38752L6.78495 1.62118C6.96212 1.44401 7.08212 1.21215 7.08212 0.92776C7.08212 0.395529 6.68302 0 6.12134 0C5.8767 0 5.64059 0.0972805 5.424 0.316358L0.309264 5.42328C0.115749 5.61147 0 5.87811 0 6.14546Z" fill="#000000" fill-opacity="0.85"/>
                    </g>
                </svg>
            </b-button>
            <div class="upper-logo d-none d-md-block">
                <img :src="logoSrc" alt="logo" class="signup-logo" />
            </div>
        </div>


        <div class="container">
            <b-card class="main-content shadow border-0 rounded-lg w-100">
                <spinner-standard :show="loading" :text="loadingMsg"></spinner-standard>
                <div id="steps">
                    <transition name="fade" mode="out-in">
                        <div :key="tabIndex" class="signup-step text-center">
                            <template v-if="tabIndex === 0">
                                <div class="mobile-logo d-block d-md-none">
                                    <img :src="logoSrc" alt="logo" class="signup-logo" />
                                </div>
                                <img src="/img/icons/trial_icon.svg" alt="Success" class="img-fluid mb-3" />
                                <h1 class="mb-3">Signup for a free, fully featured <span class="text-secondary">14-day trial</span></h1>
                                <p>Discover the wonderful opportunities with us!</p>
                            </template>

                            <template v-else-if="tabIndex === 1">
                                <h1 class="mb-3">Step 1: Your current business card</h1>
                                <p>Upload a photo of your existing business card. The system will automatically load your data from it for the next step</p>
                                <transition name="fade" mode="out-in">
                                    <div v-if="!noBcard">
                                        <div id="card-dropzones" class="mt-5 flex-column flex-md-row">
                                            <div class="qf-dropzone" id="dropzone01">
                                                <label for="card-front">
                                                    <div id="image-container-front" >
                                                        <img v-if="imageBase64Front" :src="imageBase64Front" alt="Scanned Business Card" />
                                                    </div>
                                                    <input @change="filesChange($event, 'Front')" type="file"
                                                           id="card-front"
                                                           name="business_card_front" accept="image/*"
                                                           capture="environment" required>
                                                    <div class="txt">
                                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="19.8099" height="15.7065">
                                                            <g>
                                                                <rect height="15.7065" opacity="0" width="19.8099" x="0" y="0"/>
                                                                <path d="M2.6469 15.2289L16.7542 15.2289C18.4786 15.2289 19.4036 14.3145 19.4036 12.6033L19.4036 4.39128C19.4036 2.68005 18.4786 1.77102 16.7542 1.77102L14.6838 1.77102C14.0741 1.77102 13.8667 1.67086 13.4729 1.27278L12.8604 0.629282C12.4399 0.204563 12.0241 0 11.2343 0L8.12954 0C7.34225 0 6.92393 0.204563 6.50598 0.629282L5.89092 1.27278C5.49712 1.66553 5.28691 1.77102 4.68001 1.77102L2.6469 1.77102C0.919685 1.77102 0 2.68005 0 4.39128L0 12.6033C0 14.3145 0.919685 15.2289 2.6469 15.2289ZM2.79171 13.4891C2.11801 13.4891 1.73977 13.1421 1.73977 12.424L1.73977 4.57587C1.73977 3.85777 2.11801 3.51079 2.79171 3.51079L5.22356 3.51079C5.92668 3.51079 6.27968 3.3904 6.67528 2.96284L7.25732 2.32751C7.68773 1.8598 7.93701 1.73977 8.61209 1.73977L10.7518 1.73977C11.4293 1.73977 11.6761 1.86229 12.0966 2.32005L12.6911 2.96284C13.0867 3.38791 13.4397 3.51079 14.1403 3.51079L16.6041 3.51079C17.2856 3.51079 17.6639 3.85777 17.6639 4.57587L17.6639 12.424C17.6639 13.1421 17.2856 13.4891 16.6041 13.4891ZM9.70058 12.5131C12.0202 12.5131 13.8839 10.6548 13.8839 8.31672C13.8839 5.98643 12.0255 4.12811 9.70058 4.12811C7.38059 4.12811 5.51445 5.98643 5.51445 8.31672C5.51445 10.6548 7.38059 12.5131 9.70058 12.5131ZM9.70058 10.958C8.25121 10.958 7.0643 9.78455 7.0643 8.31672C7.0643 6.85173 8.24588 5.67796 9.70058 5.67796C11.1602 5.67796 12.334 6.85173 12.334 8.31672C12.334 9.78455 11.1602 10.958 9.70058 10.958ZM14.1172 5.56593C14.1172 6.14759 14.5952 6.60709 15.1662 6.60709C15.7294 6.60709 16.202 6.14226 16.202 5.56593C16.202 5.01056 15.7269 4.53011 15.1662 4.53011C14.5977 4.53011 14.1172 5.01056 14.1172 5.56593Z" fill="#000000" fill-opacity="0.85"/>
                                                            </g>
                                                        </svg>
                                                        FRONT
                                                    </div>
                                                </label>
                                            </div>
                                            <div class="qf-dropzone" id="dropzone02">
                                                <label for="card-back">
                                                    <div id="image-container-back" >
                                                        <img v-if="imageBase64Back" :src="imageBase64Back" alt="Scanned Business Card" />
                                                    </div>
                                                    <input @change="filesChange($event,'Back')" type="file"
                                                           id="card-back"
                                                           name="business_card_back" accept="image/*"
                                                           capture="environment" required>
                                                    <div class="txt">
                                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="19.8099" height="15.7065">
                                                            <g>
                                                                <rect height="15.7065" opacity="0" width="19.8099" x="0" y="0"/>
                                                                <path d="M2.6469 15.2289L16.7542 15.2289C18.4786 15.2289 19.4036 14.3145 19.4036 12.6033L19.4036 4.39128C19.4036 2.68005 18.4786 1.77102 16.7542 1.77102L14.6838 1.77102C14.0741 1.77102 13.8667 1.67086 13.4729 1.27278L12.8604 0.629282C12.4399 0.204563 12.0241 0 11.2343 0L8.12954 0C7.34225 0 6.92393 0.204563 6.50598 0.629282L5.89092 1.27278C5.49712 1.66553 5.28691 1.77102 4.68001 1.77102L2.6469 1.77102C0.919685 1.77102 0 2.68005 0 4.39128L0 12.6033C0 14.3145 0.919685 15.2289 2.6469 15.2289ZM2.79171 13.4891C2.11801 13.4891 1.73977 13.1421 1.73977 12.424L1.73977 4.57587C1.73977 3.85777 2.11801 3.51079 2.79171 3.51079L5.22356 3.51079C5.92668 3.51079 6.27968 3.3904 6.67528 2.96284L7.25732 2.32751C7.68773 1.8598 7.93701 1.73977 8.61209 1.73977L10.7518 1.73977C11.4293 1.73977 11.6761 1.86229 12.0966 2.32005L12.6911 2.96284C13.0867 3.38791 13.4397 3.51079 14.1403 3.51079L16.6041 3.51079C17.2856 3.51079 17.6639 3.85777 17.6639 4.57587L17.6639 12.424C17.6639 13.1421 17.2856 13.4891 16.6041 13.4891ZM9.70058 12.5131C12.0202 12.5131 13.8839 10.6548 13.8839 8.31672C13.8839 5.98643 12.0255 4.12811 9.70058 4.12811C7.38059 4.12811 5.51445 5.98643 5.51445 8.31672C5.51445 10.6548 7.38059 12.5131 9.70058 12.5131ZM9.70058 10.958C8.25121 10.958 7.0643 9.78455 7.0643 8.31672C7.0643 6.85173 8.24588 5.67796 9.70058 5.67796C11.1602 5.67796 12.334 6.85173 12.334 8.31672C12.334 9.78455 11.1602 10.958 9.70058 10.958ZM14.1172 5.56593C14.1172 6.14759 14.5952 6.60709 15.1662 6.60709C15.7294 6.60709 16.202 6.14226 16.202 5.56593C16.202 5.01056 15.7269 4.53011 15.1662 4.53011C14.5977 4.53011 14.1172 5.01056 14.1172 5.56593Z" fill="#000000" fill-opacity="0.85"/>
                                                            </g>
                                                        </svg>
                                                        BACK (OPTIONAL)
                                                    </div>
                                                </label>
                                            </div>

                                        </div>
                                    </div>
                                </transition>

                                <b-row>
                                    <b-col cols="12" class="text-center">
                                        <div class="d-flex pt-4 align-items-center justify-content-center">
                                            <b-form-checkbox
                                                v-model="noBcard"
                                                switch size="lg"
                                                class="mr-2" @change="noBcard ? nextStep() : () => {}">I don't have a business card</b-form-checkbox>
                                        </div>
                                    </b-col>
                                </b-row>


                            </template>


                            <template v-else-if="tabIndex === 2">
                                <!-- Content for step 2 -->
                                <h1 class="mb-3">Step 2: Personal details</h1>
                                <p>Review your personal details that will be  used to create a user for your trial and for your first business card.</p>
                                <validation-observer ref="fieldsStep2" slim>
                                    <b-form class="mt-5 text-left">
                                        <b-row>
                                            <b-col cols="6">
                                                <b-form-group  label="First name" class="required">
                                                    <validation-provider
                                                        #default="{ errors }" tag="div"
                                                        name="First name"
                                                        rules="required"
                                                    >
                                                        <b-form-input v-model="form.firstName" placeholder="Your given name"
                                                                      required
                                                                      :state="errors.length > 0 ? false:null"
                                                        >
                                                        </b-form-input>
                                                        <div class="position-relative"><small class="text-danger qf-input-error">{{ errors[0] }}</small></div>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col cols="6">
                                                <b-form-group  label="Last name" class="required">
                                                    <validation-provider
                                                        #default="{ errors }" tag="div"
                                                        name="Last name"
                                                        rules="required"
                                                    >
                                                        <b-form-input v-model="form.lastName"
                                                                      placeholder="Your family name"
                                                                      :state="errors.length > 0 ? false:null"
                                                                      required
                                                        ></b-form-input>
                                                        <div class="position-relative"><small class="text-danger qf-input-error">{{ errors[0] }}</small></div>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col cols="12" md="6">
                                                <b-form-group  label="Position" class="required">
                                                    <validation-provider
                                                        #default="{ errors }" tag="div"
                                                        name="Position"
                                                        rules="required"
                                                    >
                                                    <b-form-input v-model="form.position"
                                                                  placeholder="e.g. CTO"
                                                                  :state="errors.length > 0 ? false:null"
                                                                  required></b-form-input>
                                                    <div class="position-relative"><small class="text-danger qf-input-error">{{ errors[0] }}</small></div>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col cols="12" md="6">
                                                <b-form-group  label="E-mail" class="required">
                                                    <validation-provider
                                                        #default="{ errors }" tag="div"
                                                        name="Email"
                                                        rules="email|required"
                                                    >
                                                    <b-form-input v-model="form.email" placeholder="someone@acme.inc"
                                                                  :state="errors.length > 0 ? false:null"
                                                                  @blur="checkExistance('email')"
                                                                  required></b-form-input>
                                                        <div class="position-relative"><small class="text-danger qf-input-error">{{ errors[0] }}</small></div>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col cols="12" md="6">
                                                <b-form-group  label="Mobile" class="required">
                                                    <validation-provider
                                                        #default="{ errors }" tag="div"
                                                        name="mobile phone number"
                                                        rules="phoneNumber|required"
                                                    >
                                                    <b-form-input
                                                        v-model="form.mobile_phone"
                                                        :state="errors.length > 0 ? false:null"
                                                        placeholder="+359-...." required></b-form-input>
                                                    <div class="position-relative">
                                                        <small class="text-danger qf-input-error">{{ errors[0] }}</small>
                                                    </div>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col cols="12" md="6">
                                                <b-form-group  label="Office">
                                                    <validation-provider
                                                        #default="{ errors }" tag="div"
                                                        name="The mobile phone"
                                                        rules="phoneNumber"
                                                    >
                                                        <b-form-input
                                                            v-model="form.secondary_phone"
                                                            :state="errors.length > 0 ? false:null"
                                                            placeholder="+359-...." ></b-form-input>
                                                        <div class="position-relative">
                                                            <small class="text-danger qf-input-error">{{ errors[0] }}</small>
                                                        </div>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>


                                    </b-form>
                                </validation-observer>
                            </template>
                            <!-- Step 3 / Index 3 - Company details -->
                            <template v-else-if="tabIndex === 3">
                                <h1 class="mb-3">Step 3: Company details</h1>
                                <p>Review the company details that were able to fetch from your card and website</p>
                                <b-form class="mt-5">
                                    <b-row>
                                        <b-col cols="6">
                                            <div class="qf-dropzone logo-zone" id="dropzone03">
                                                <label for="company-logo">
                                                    <div id="company-logo-placegolder" class="label-img">
                                                        <img v-if="imageBase64Logo" :src="imageBase64Logo" alt="Your company logo" />
                                                    </div>
                                                    <input @change="filesChange($event, 'Logo')" type="file"
                                                           id="company-logo"
                                                           name="company_logo" accept="image/*"
                                                           capture="environment" required>
                                                    <div class="txt">COMPANY LOGO</div>
                                                    <span class="ecb-clarification">PNG or SVG image</span>
                                                </label>
                                            </div>
                                        </b-col>
                                        <b-col cols="6">
                                            <div class="card-dropzones mb-4">
                                                <div class="qf-dropzone  logo-zone" id="dropzone04">
                                                    <label for="card-icon">
                                                        <div id="company-icon-placeholder" class="label-img">
                                                            <img v-if="imageBase64Icon" :src="imageBase64Icon" alt="The icon for your app (optional)" />
                                                        </div>
                                                        <input @change="filesChange($event, 'Icon')" type="file"
                                                               id="card-icon"
                                                               name="company_icon" accept="image/*"
                                                               capture="environment" required>
                                                        <div class="txt">ICON (OPTIONAL)</div>
                                                        <span class="ecb-clarification">512px x 521px PNG</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col cols="12">
                                            <p class="font-small-2 text-muted ">Note: Automatic fetching of company logo and icon from your website is still in BETA and is not yet 100% accurate.</p>
                                        </b-col>
                                    </b-row>
                                    <validation-observer ref="fieldsStep3" slim>
                                        <b-row>
                                            <b-col cols="12" md="6">
                                                <b-form-group  label="Company name" class="required">
                                                    <validation-provider
                                                        #default="{ errors }" tag="div"
                                                        name="Company name"
                                                        rules="required"
                                                    >
                                                        <b-form-input v-model="form.company"
                                                                      :state="errors.length > 0 ? false:null"
                                                                      placeholder="Acme Inc." required></b-form-input>
                                                        <div class="position-relative">
                                                            <small class="text-danger qf-input-error">{{ errors[0] }}</small>
                                                        </div>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col cols="12" md="6">
                                                <b-form-group  label="Company website">
                                                    <b-form-input
                                                        v-model="form.website" placeholder="https://..."
                                                        @blur="fetchLogos()"
                                                        required></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col cols="6">
                                                <b-form-group  label="Street address" class="required">
                                                    <validation-provider
                                                        #default="{ errors }" tag="div"
                                                        name="street address"
                                                        rules="required"
                                                    >
                                                        <b-form-input
                                                            v-model="addressData.address_street"
                                                            :state="errors.length > 0 ? false:null"
                                                            placeholder="e.g. Main street 55..." required></b-form-input>
                                                    <div class="position-relative">
                                                        <small class="text-danger qf-input-error">{{ errors[0] }}</small>
                                                    </div>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col cols="6">
                                                <b-form-group  label="City" class="required">
                                                    <validation-provider
                                                        #default="{ errors }" tag="div"
                                                        name="city name"
                                                        rules="required"
                                                    >
                                                        <b-form-input
                                                            v-model="addressData.address_city"
                                                            :state="errors.length > 0 ? false:null"
                                                            placeholder="e.g. Sofia" required></b-form-input>
                                                        <div class="position-relative">
                                                            <small class="text-danger qf-input-error">{{ errors[0] }}</small>
                                                        </div>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col cols="6">

                                                <b-form-group  label="ZIP" class="required">
                                                    <validation-provider
                                                        #default="{ errors }" tag="div"
                                                        name="zip code"
                                                        rules="required"
                                                    >
                                                        <b-form-input v-model="addressData.address_zip"
                                                                      :state="errors.length > 0 ? false:null"
                                                                      placeholder="12345" required></b-form-input>
                                                    <div class="position-relative">
                                                        <small class="text-danger qf-input-error">{{ errors[0] }}</small>
                                                    </div>
                                                </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col cols="6">
                                                <b-form-group  label="Country" class="required">
                                                    <validation-provider
                                                        #default="{ errors }" tag="div"
                                                        name="country name"
                                                        rules="required"
                                                    >
                                                        <b-form-input v-model="country"
                                                                      :state="errors.length > 0 ? false:null"
                                                                      placeholder="e.g. Switzerland" required></b-form-input>
                                                        <div class="position-relative">
                                                            <small class="text-danger qf-input-error">{{ errors[0] }}</small>
                                                        </div>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </validation-observer>
                                </b-form>

                            </template>

                            <!-- Step 4 / Index 4 - Layout -->
                            <template v-else-if="tabIndex === 4">
                                <b-row>
                                    <b-col cols="12">
                                        <h2 class="mb-3 text-left"><strong>Step 4:</strong> Select a digital card layout</h2>
                                        <p class="text-left font-small-2">You are selecting only the general structure for your card layout.
                                            <br/>
                                            Our designers will check the corporate identity of your website and current business card and will get back to you with a custom proposal.
                                        </p>
                                        <div class="qf-card-layouts my-4">
                                            <b-card-group row>
                                                <b-card
                                                        no-body
                                                        v-for="cardLayout in cardLayouts"
                                                        :key="cardLayout.title"
                                                        :img-src="cardLayout.imgSrc"
                                                        :img-alt="cardLayout.title"
                                                        img-top
                                                        class="mb-3 pb-o qf-transparent-card mr-2"
                                                        :class="selectedCardLayout == cardLayout.title ? 'qf-selected-layout' : ''"
                                                        @click="selectedCardLayout = cardLayout.title"
                                                >
                                                    {{cardLayout.title}}
                                                </b-card>
                                            </b-card-group>
                                        </div>
                                        <div class="qf-checks mt-3 text-left pb-3">
                                            <b-form-checkbox
                                                    id="checkbox-privacy"
                                                    v-model="termsGeneralAccepted"
                                                    name="checkbox-privacy"
                                                    value="accepted"
                                                    size="lg"
                                                    unchecked-value="not_accepted"
                                            >
                                                I have read and agree with the <a :href="termsLink" target="_blank">data protection and privacy policy</a><span class="text-danger">*</span>
                                            </b-form-checkbox>
                                        </div>
                                    </b-col>
                                </b-row>

                            </template>

                            <!-- Step 5 / Index 5 - Thank you! -->
                            <template v-else-if="tabIndex === 5">
                                <img v-if="processFinished" src="/img/icons/thumbs_up.svg" alt="Success" class="img-fluid mb-3" />
                                <h1 class="mb-3" v-if="processFinished">Thank you for signing-up!</h1>
                                <p v-if="processFinished" >Our design team will get back to you within a couple of working days <br>with your digital card design proposals!</p>
                            </template>
                        </div>
                    </transition>
                </div>
            </b-card>
        </div>
        <div class="sticky-footer bg-white py-3 text-center">
            <div class="container text-center w-100">
                <div class="d-flex justify-content-between align-items-center w-100">
                    <b-button v-if="!processFinished" @click="previousStep" :disabled="tabIndex === 0" class="d-none d-md-block " :class="tabIndex < 2 ? 'invisible': ''"><font-awesome-icon :icon="['fas', 'chevron-left']" class="mr-2"></font-awesome-icon> Back</b-button>
                    <div :class="tabIndex < 5 && tabIndex !== 0 ? '' : 'invisible'">{{ tabIndex }} / 5</div>
                    <b-button v-if="tabIndex < 5" @click="nextStep" :disabled="isNextDisabled" class="px-3 text-uppercase">{{nextLabel}}<font-awesome-icon :icon="['fas', 'chevron-right']" class="ml-2"></font-awesome-icon> </b-button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import Api from "@/store/services/Api";
import tab from "bootstrap/js/src/tab";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, phoneNumber, positive } from '@/utils/validations/validations';
import Parse from "parse";
export default {
    name: "SignupAuto",
    components: {
        ValidationProvider,
        ValidationObserver
    },
    setup() {
        return {

            required,
            email,
            phoneNumber,
            positive
        }
    },
    data() {
        return {
            processFinished: false,
            loading: false,
            lang: 'en',
            country: '',
            loadingMsg: 'Processing...',
            termsLink: '../../assets/docs/Qfecard Terms and conditions.pdf',
            form: {
                email: "",
                firstName: "",
                lastName: "",
                position: "",
                company: "",
                mobile_phone: "",
                secondary_phone: "",
                website: "",
                companyAddress: "",
            },
            noBcard: false,
            addressData: {},
            selectedCardLayout: 'Standard',
            clientOf: 'ERN2YFMPwe',//Qfecard Prospects
            tabIndex: 0,
            imageCardBack: {},
            imageCardFront: {},
            imageCardLogo: {},
            imageCardIcon: {},
            imageBase64Front: null,
            imageBase64Back: null,
            imageBase64Logo: null,
            imageBase64Icon: null,
            termsPrivacyAccepted: false,
            termsGeneralAccepted: false,
            cardLayouts: [
                {
                    title: "Standard",
                    imgSrc: require('../../../assets/images/layouts/cardLayout-01.png'),
                },
                {
                    title: "Two tone",
                    imgSrc: require('../../../assets/images/layouts/cardLayout-02.png'),
                },
                {
                    title: "Background Image",
                    imgSrc: require('../../../assets/images/layouts/cardLayout-03.png'),
                },
                {
                    title: "Profile photo",
                    imgSrc: require('../../../assets/images/layouts/cardLayout-04.png'),
                },
                {
                    title: "Geometric",
                    imgSrc: require('../../../assets/images/layouts/cardLayout-05.png'),
                },
                {
                    title: "Color Gradient",
                    imgSrc: require('../../../assets/images/layouts/cardLayout-06.png'),
                },
            ],
        };
    },
    mounted(){
        if(this.$route.query.nm) {
            this.tabIndex = 1;
        }
        if(this.$route.params.agencyId){
            this.clientOf = this.$route.params.agencyId;
        }
        this.fetchTermsDocument();
    },
    methods: {
        fetchTermsDocument(lang='en'){
            let query = new Parse.Query('ProtectedDoc');
            query.equalTo('type', 'privacy_policy');
            query.equalTo('language', lang);
            query.descending('revision');
            query.first().then((doc) => {
                if(doc){
                    this.termsLink = doc.attributes.attachment.url();
                    this.form.licenseId = doc.id;
                }
            }).catch((error) => {
                console.error('Error while fetching terms document', error);
            });
        },
        resetForm() {
            this.form = {
                email: "",
                firstName: "",
                lastName: "",
                company: "",
                position: "",
                mobile_phone: "",
                secondary_phone: "",
                website: "",
                companyAddress: "",
                addressData: {},
            };
            this.imageCardLogo = {};
            this.imageCardIcon = {};
            this.imageBase64Logo = null;
            this.imageBase64Icon = null;
            this.termsPrivacyAccepted = false;
            this.termsGeneralAccepted = false;
            this.selectedCardLayout = 'Standard';
        },


        resizeImage(file, maxWidth, maxHeight) {
            return new Promise((resolve, reject) => {
                let reader = new FileReader();
                reader.onload = (event) => {
                    let img = new Image();
                    img.onload = () => {
                        let canvas = document.createElement('canvas');
                        let ctx = canvas.getContext('2d');
                        let width = img.width;
                        let height = img.height;

                        if (width > height) {
                            if (width > maxWidth) {
                                height *= maxWidth / width;
                                width = maxWidth;
                            }
                        } else {
                            if (height > maxHeight) {
                                width *= maxHeight / height;
                                height = maxHeight;
                            }
                        }

                        canvas.width = width;
                        canvas.height = height;
                        ctx.drawImage(img, 0, 0, width, height);

                        canvas.toBlob((blob) => {
                            let resizedFile = new File([blob], file.name, {
                                type: 'image/jpeg',
                                lastModified: Date.now()
                            });
                            resolve(resizedFile);
                        }, 'image/jpeg', 0.7);
                    };
                    img.src = event.target.result;
                };
                reader.onerror = reject;
                reader.readAsDataURL(file);
            });
        },

        assembleAddress(addressData){
            let address = '';
            if(addressData.address_street){
                address += addressData.address_street;
            }
            if(addressData.address_city){
                address += ', ' + addressData.address_city;
            }
            if(addressData.address_zip){
                address += ', ' + addressData.address_zip;
            }

            addressData.address_country ? address += ', ' + addressData.address_country : address += this.country;

            return address;
        },

        submitScans() {
            let formData = new FormData();
            let info = {
                channel: 'signup',
                promo: '',
                source: 'web',
            }

            formData.append('action', 'extractCardData');
            formData.append('business_card_front', this.imageCardFront);
            formData.append('data',  JSON.stringify(info));

            if(this.imageCardBack.size){
                formData.append('business_card_back', this.imageCardBack);
            }

            this.loading = true;
            Api().post('/helpers/endpoint-controllers/extract-card_data.php', formData).then(response => {

                this.form.firstName = response.data.first_name;
                this.form.lastName = response.data.last_name;
                this.form.position = response.data.position;
                this.form.mobile_phone = response.data.mobile_phone;
                this.form.secondary_phone = response.data.secondary_phone;
                this.form.company = response.data.company;
                this.form.website = response.data.website;

                this.form.email = response.data.email;
                this.form.companyAddress = this.assembleAddress(response.data);
                this.form.addressStreet = response.data.address_street;
                this.form.addressCity = response.data.address_city;
                this.form.addressZip = response.data.address_zip;
                this.form.addressCountry = response.data.address_country;
                this.country = response.data.address_country;

                this.addressData = response.data;
                this.addressData.address_country = this.country;

                if(response.data.website){
                    this.fetchLogos();
                } else {
                    this.loading = false;
                }

                console.log(response);

            }).catch(error => {
                console.log(error);
                this.loading = false;
            });
        },

        fetchLogos(){
            this.loadingMsg = 'Attempting to fetch logo..';
            this.loading = true;
            let newFormData = new FormData();
            newFormData.append('action', 'extractWebsiteData');

            Api().get('/helpers/endpoint-controllers/extract-company_logo.php?url='+this.form.website).then(logoresp => {
                if(logoresp.data.logo && logoresp.data.logo.url) {
                    this.imageBase64Logo = logoresp.data.logo.url;
                    this.imageCardLogo = logoresp.data.logo.url;
                }

                if(logoresp.data.favicon && logoresp.data.favicon.url) {
                    this.imageBase64Icon = logoresp.data.favicon.url;
                    this.imageCardIcon = logoresp.data.favicon.url;
                }
                console.log("Logoresp: ", logoresp);
                this.loading = false;
                this.loadingMsg = 'Processing...';
            }).catch(error => {
                console.log(error);
                this.loadingMsg = 'Processing...';
                this.loading = false;
            });
        },

        signup() {
            let formData = new FormData();
            formData.append('action', 'signupAuto');
            formData.append('cardLayout', this.selectedCardLayout);
            formData.append('clientOf', this.clientOf)

            if(this.$route.query.nm){
                formData.append('noClientMail', this.$route.query.nm);
            }

            //Company requirements
            formData.append('company', this.form.company);
            formData.append('email', this.form.email);
            formData.append('website', this.form.website);
            formData.append('phone', this.form.mobile_phone);
            formData.append('secondaryPhone', this.form.secondary_phone);
            formData.append('address', this.form.companyAddress ?  this.form.companyAddress : this.assembleAddress(this.addressData));

            //Office Address
            formData.append('street', this.addressData.address_street);
            formData.append('city', this.addressData.address_city);
            formData.append('zip', this.addressData.address_zip);
            formData.append('country', this.addressData.address_country);
            formData.append('officePhone', this.form.secondary_phone);

            //Employee data
            formData.append('first_name', this.form.firstName);
            formData.append('last_name', this.form.lastName);
            formData.append('position', this.form.position);

            //Logos
            formData.append('company_logo', this.imageCardLogo);
            formData.append('company_icon', this.imageCardIcon);

            formData.append('card_front', this.imageCardFront);
            formData.append('card_back', this.imageCardBack);

            formData.append('card_layout', this.selectedCardLayout);


            this.loading = true;
            this.loadingMsg = 'Setting up your account..'

            Api().post('helpers/api.php', formData).then(response => {
                console.log(response);
                this.processFinished = true;
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.loading = false;
                this.loadingMsg = 'Processing..'
            });
        },
        nextStep() {
            console.log()
            if (this.tabIndex < 5) {

                if(this.tabIndex == 2){
                    this.$refs.fieldsStep2.validate().then(success => {
                        if (success) {
                            let available = this.checkExistance('email');
                            if(available){
                                this.tabIndex++;
                            }else {
                                console.log("Error");
                            }
                        }else {
                            console.log("Error");
                        }
                    });
                } else if(this.tabIndex == 3){
                    this.$refs.fieldsStep3.validate().then(success => {
                        if (success) {
                            let available = this.checkExistance('company');
                            if(available){
                                this.tabIndex++;
                            }else {
                                console.log("Error");
                            }
                        }else {
                            console.log("Error");
                        }
                    });
                } else {
                    this.tabIndex++;
                }


            }

        },
        previousStep() {
            if (this.tabIndex > 0) {
                this.tabIndex--;
            }
        },
        getFile() {
            const file = document.getElementById('card-front').files[0];
            this.imageCardFront = file;
        },
        sanitizeFileName(fileName) {
            return fileName.replace(/[^a-zA-Z0-9.-]/g, '_');
        },
        async checkExistance(type){
            let formData = new FormData();
            formData.append('action', 'checkExistance');
            formData.append(type, this.form[type]);
            await Api().post('/helpers/endpoint-controllers/checkExistance.php', formData).then(response => {
                console.log(response);
                if(response.data[type]){
                    this.$bvToast.toast('This ' + type + ' is already in use. Please choose another one.', {
                        title: 'Error',
                        variant: 'danger',
                        solid: true
                    });
                    return false;
                } else {
                    return true;
                }
            }).catch(error => {
                console.log(error);
                return false;
            });
        },
        async filesChange(event, type) {
            if (event.target.files[0]) {
                let file = event.target.files[0];
                let fileType = file.type;

                if (fileType.includes('image')) {
                    this.loadingMsg = 'Generating preview...';
                    this.loading = true;
                    try {
                        // Resize the image
                        const resizedFile = await this.resizeImage(file, 1500, 1500);
                        this['imageCard' + type] = resizedFile;

                        // Create a preview
                        let reader = new FileReader();
                        reader.onloadend = () => {
                            this['imageBase64' + type] = reader.result;
                        };
                        reader.readAsDataURL(resizedFile);
                        this.loading = false;
                        this.loadingMsg = 'Processing...';
                    } catch (error) {
                        console.error('Error resizing image:', error);
                        this.loading = false;
                        this.loadingMsg = 'Processing...';
                    }
                }
            }
        },
    },
    computed: {
        tab() {
            return tab
        },
        nextLabel() {
            return this.tabIndex === 4 ? 'Signup' : 'Next';
        },
        isNextDisabled() {
            switch(this.tabIndex) {
                case 0:
                    return false;
                case 1:
                    return !this.imageCardFront.size;
                case 3:
                    return !this.imageCardBack;
                case 4:
                    return !this.termsGeneralAccepted;
                default:
                    return false;
            }
        },
        logoSrc() {
            return require('../../../assets/images/qfecard-logo.svg');
        },
    },
    watch: {
        country: {
            handler: function (newCountry) {
                if(newCountry){
                    this.addressData.address_country = newCountry;
                    if(newCountry.includes('Bulgaria')){
                        this.lang='bg';
                    }else if (
                        newCountry.includes('Germany') ||
                        newCountry.includes('Deutschland') ||
                        newCountry.includes('Austria') ||
                        newCountry.includes('Switzerland') ||
                        newCountry.includes('Liechtenstein')
                    ){
                        this.lang = 'de';
                    }else {
                        this.lang = 'en';
                    }
                }
            },
        },
        lang: function (val) {
            this.fetchTermsDocument(val);
        },
        tabIndex(newVal, prevVal) {
            // console.log('Tab index: ',newVal);
            // debugger;
            if(newVal == 2 && prevVal == 1) {

                this.submitScans();

            } else if (newVal == 5 && prevVal == 4) {
                this.signup();
            }
        },
        imageCardFront(newVal) {
            if(newVal) {
                this.resetForm();
            }
        },
    }
};
</script>

<style>
span.ecb-clarification {
    display: block;
    font-size: 0.7em;
    background: #DDD;
}

.sticky-header,
.sticky-footer{
    height: 70px    ;
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.15);
    z-index: 99;
}
.sticky-footer{
    top:auto;
    bottom: 0;
}
.main-content{
    max-height: calc(100% - 200px);
    min-height: 600px;
}

.card-body{
 display: flex;
    align-items: center;
}

.qf-transparent-card {
    background-color: transparent;
    border: 1px solid transparent;
    box-shadow: none;
    cursor: pointer;
}

.upper-logo img{
    width: 100%;
    max-width: 160px;
    height: auto;
}

#steps {
    margin: 0 auto;
    max-width: 1000px;
    width: 100%;
}

#card-dropzones, .card-dropzones {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 10px;
}

.qf-dropzone {
    margin: 0 auto;
    width: 100%;
}


#image-container-front,
#image-container-back,
.label-img{
    position: relative;
    aspect-ratio: 9 / 5;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fff;
}

#image-container-front{
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 407.9 231.9" fill="%23ddd"><path d="M143.3,140c-2.2,0-4-1.8-4-4s1.8-4,4-4h0.1c2.2,0,4,1.8,4,4S145.5,140,143.3,140z"/><path d="M75.1,108c-17.6,0-32-14.4-32-32s14.4-32,32-32s32,14.4,32,32S92.8,108,75.1,108z M75.1,52c-13.2,0-24,10.8-24,24s10.8,24,24,24s24-10.8,24-24S88.3,52,75.1,52z"/><path d="M207,78h-63.9c-2.2,0-4-1.8-4-4V64c0-2.2,1.8-4,4-4H207c2.2,0,4,1.8,4,4v10C211,76.2,209.3,78,207,78z"/><path d="M350.8,78H226.8c-2.2,0-4-1.8-4-4V64c0-2.2,1.8-4,4-4h123.9c2.2,0,4,1.8,4,4v10C354.8,76.2,353,78,350.8,78z"/><path d="M327,140h-168c-2.2,0-4-1.8-4-4s1.8-4,4-4h168c2.2,0,4,1.8,4,4S329.3,140,327,140z"/><path d="M143.3,164c-2.2,0-4-1.8-4-4s1.8-4,4-4h0.1c2.2,0,4,1.8,4,4S145.5,164,143.3,164z"/><path d="M247.1,164h-88c-2.2,0-4-1.8-4-4s1.8-4,4-4h88c2.2,0,4,1.8,4,4S249.3,164,247.1,164z"/><path d="M143.3,188c-2.2,0-4-1.8-4-4s1.8-4,4-4h0.1c2.2,0,4,1.8,4,4S145.5,188,143.3,188z"/><path d="M303,188h-144c-2.2,0-4-1.8-4-4s1.8-4,4-4h144c2.2,0,4,1.8,4,4S305.3,188,303,188z"/><path d="M231.1,108h-88c-2.2,0-4-1.8-4-4s1.8-4,4-4h88c2.2,0,4,1.8,4,4S233.3,108,231.1,108z"/><path d="M63.1,92c-0.8,0-1.7-0.3-2.4-0.8c-1.8-1.3-2.1-3.8-0.8-5.6l12-16c1.3-1.8,3.8-2.1,5.6-0.8c1.8,1.3,2.1,3.8,0.8,5.6l-12,16C65.5,91.4,64.3,92,63.1,92z"/><path d="M87.1,92c-1.2,0-2.4-0.5-3.2-1.6l-12-16c-1.3-1.8-1-4.3,0.8-5.6c1.8-1.3,4.3-1,5.6,0.8l12,16c1.3,1.8,1,4.3-0.8,5.6C88.8,91.7,87.9,92,87.1,92z"/></svg>');
}


#image-container-back,
#company-logo-placegolder {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 407.9 231.9" fill="%23ddd"><path d="M204,180c-37.5,0-68-30.5-68-68c0-37.5,30.5-68,68-68c37.5,0,68,30.5,68,68C271.9,149.5,241.4,180,204,180z M204,52c-33.1,0-60,26.9-60,60s26.9,60,60,60s60-26.9,60-60S237,52,204,52z"/><path d="M172,156c-0.8,0-1.7-0.3-2.4-0.8c-1.8-1.3-2.1-3.8-0.8-5.6l24-32c1.3-1.8,3.8-2.1,5.6-0.8c1.8,1.3,2.1,3.8,0.8,5.6l-24,32C174.4,155.4,173.2,156,172,156z"/><path d="M202,132c-1.2,0-2.4-0.6-3.2-1.6l-6-8c-1.3-1.8-1-4.3,0.8-5.6c1.8-1.3,4.3-1,5.6,0.8l6,8c1.3,1.8,1,4.3-0.8,5.6C203.6,131.7,202.8,132,202,132z"/><path d="M195.9,156c-0.8,0-1.6-0.2-2.3-0.7c-1.8-1.3-2.3-3.7-1-5.6l44-64c1.2-1.8,3.7-2.3,5.6-1c1.8,1.3,2.3,3.7,1,5.6l-44,64C198.5,155.4,197.2,156,195.9,156z"/><path d="M256.5,116c-1.3,0-2.5-0.6-3.3-1.7l-16.5-24c-1.2-1.8-0.8-4.3,1-5.6c1.8-1.2,4.3-0.8,5.6,1l16.5,24c1.2,1.8,0.8,4.3-1,5.6C258,115.8,257.2,116,256.5,116z"/><path d="M184,100c-8.8,0-16-7.2-16-16s7.2-16,16-16s16,7.2,16,16S192.8,100,184,100z M184,76c-4.4,0-8,3.6-8,8c0,4.4,3.6,8,8,8s8-3.6,8-8C192,79.6,188.4,76,184,76z"/><path d="M108,108H44c-2.2,0-4-1.8-4-4c0-2.2,1.8-4,4-4h64c2.2,0,4,1.8,4,4C112,106.2,110.2,108,108,108z"/><path d="M108,124H44c-2.2,0-4-1.8-4-4c0-2.2,1.8-4,4-4h64c2.2,0,4,1.8,4,4C112,122.2,110.2,124,108,124z"/><path d="M363.9,108h-64c-2.2,0-4-1.8-4-4c0-2.2,1.8-4,4-4h64c2.2,0,4,1.8,4,4C367.9,106.2,366.1,108,363.9,108z"/><path d="M363.9,124h-64c-2.2,0-4-1.8-4-4c0-2.2,1.8-4,4-4h64c2.2,0,4,1.8,4,4C367.9,122.2,366.1,124,363.9,124z"/></svg>');
    background-size: 80%;
}

#company-icon-placeholder {
    background-image: url('data:image/svg+xml,<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 264 150" enable-background="new 0 0 264 150" xml:space="preserve"><path fill-rule="evenodd" clip-rule="evenodd" fill="%23DDDDDD" d="M152.2,42.1c-4.8-0.6-11-0.6-19.7-0.6c-8.7,0-15,0-19.7,0.6c-4.7,0.6-7.5,1.8-9.6,3.9c-2.1,2.1-3.3,4.9-3.9,9.6c-0.6,4.8-0.6,11-0.6,19.7c0,8.7,0,15,0.6,19.7c0.4,3.2,1.1,5.6,2.2,7.4l58.1-58.1C157.8,43.3,155.4,42.6,152.2,42.1z M163.5,48.2l-27.1,27.1l27.1,27.1c1.1-1.8,1.8-4.2,2.2-7.4c0.6-4.8,0.6-11,0.6-19.7c0-8.7,0-15-0.6-19.7C165.3,52.4,164.6,50.1,163.5,48.2z M159.6,106.3l-27.1-27.1l-27.1,27.1c1.8,1.1,4.2,1.8,7.4,2.2c4.8,0.6,11,0.6,19.7,0.6c8.7,0,15,0,19.7-0.6C155.4,108.1,157.8,107.4,159.6,106.3z M152.9,36.7c5.3,0.7,9.5,2.2,12.7,5.5c3.3,3.3,4.8,7.4,5.5,12.7c0.7,5.2,0.7,11.8,0.7,20.2v0.4c0,8.4,0,15.1-0.7,20.2c-0.7,5.3-2.2,9.5-5.5,12.7c-3.3,3.3-7.4,4.8-12.7,5.5c-5.2,0.7-11.8,0.7-20.2,0.7h-0.4c-8.4,0-15.1,0-20.2-0.7c-5.3-0.7-9.5-2.2-12.7-5.5c-3.3-3.3-4.8-7.4-5.5-12.7c-0.7-5.2-0.7-11.8-0.7-20.2v-0.4c0-8.4,0-15.1,0.7-20.2c0.7-5.3,2.2-9.5,5.5-12.7c3.3-3.3,7.4-4.8,12.7-5.5c5.2-0.7,11.8-0.7,20.2-0.7h0.4C141.2,36,147.8,36,152.9,36.7z"/></svg>');
}

.qf-dropzone img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
}

.qf-dropzone.logo-zone img {
    object-fit: contain;
    background: #DDD;
}

.qf-dropzone label {

    height: auto;
    margin: 0 auto;
    display: block;
    position: relative;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
}

.qf-dropzone input[type='file'] {
    position: absolute;
    inset: 0;
    opacity: 0;
}

.txt {
    background: #ddd;
    font-size: 12px;
    font-weight: bold;
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.txt svg{
    margin-right: 5px;
}
.qf-dropzone label > div:has(img) {
    background-image: none !important;
}
.qf-selected-layout:before {
    content: '';
    position: absolute;
    width: 50px;
    height: 50px;
    z-index: 5;
    background: var(--color-secondary);
    border-radius: 50%;
    inset: 50%;
    transform: translate(-50%, -50%);
}
.qf-selected-layout:after {
    content: "";
    display: block;
    inset: 50%;
    box-sizing: border-box;
    position: absolute;
    width: 12px;
    height: 25px;
    border-width: 0 4px 4px 0;
    border-style: solid;
    transform: translate(-50%, -56%) rotate(45deg);
    color: #fff;
    z-index: 6;
}

.qf-card-layouts .card {
    opacity: 0.5;
}

.qf-card-layouts:has(.qf-selected-layout),.qf-card-layouts .qf-selected-layout, .qf-card-layouts .card:has(.qf-selected-layout) {
    opacity: 1;
}
.mobile-logo img{
    max-width: 218px;
}
.mobile-logo{
    margin-bottom: 15vh;
    margin-top: -15vh;
}
button.mobile-back {
    background: transparent !important;
    border: none;
    margin-right: auto;
}

button.mobile-back svg {
    width: 20px;
    height: auto;
}

button.mobile-back.disabled {
    display: none !important;
}
@media (max-width: 767.98px) {
    #image-container-front, #image-container-back{
        aspect-ratio: auto;
        background: none;
    }
    .qf-dropzone label:has(img) > * {
        opacity: 0.3;
    }
    .qf-dropzone label:has(img):before{
        content: '';
        position: absolute;
        width: 30px;
        height: 30px;
        z-index: 5;
        background: var(--color-secondary);
        border-radius: 50%;
        inset: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        opacity: 1;
    }
    .qf-dropzone label:has(img):after{
        content: '';
        display: block;
        inset: 50%;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: absolute;
        width: 8px;
        height: 17px;
        border-width: 0 3px 3px 0;
        border-style: solid;
        -webkit-transform: translate(-50%, -56%) rotate(45deg);
        transform: translate(-50%, -56%) rotate(45deg);
        color: #fff;
        z-index: 6;
        opacity: 1;
    }
    .main-content {
        background: transparent;
        box-shadow: none !important;
    }
    .txt{
        background: #fff;
        padding: 12px;
    }
    .main-content {
        background: transparent;
        box-shadow: none !important;
    }

    .sticky-header, .sticky-footer {
        box-shadow: none;
        background-color: transparent !important;
    }
    .qf-dropzone label:has(img) > * {
        opacity: 0.3;
    }
    .card-group {
        display: flex;
        scroll-snap-type: x mandatory;
        overflow-x: scroll;
        box-sizing: border-box;
        padding: 0 35px;
    }
    .card-group > .card {
        min-width: 120px;
        height: auto;
        scroll-snap-align: center;
    }
    .qf-card-layouts {
        margin: 0 -35px;
    }
}
</style>
